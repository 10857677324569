import { createRouter, createWebHashHistory } from 'vue-router'
import HelloWorld from '../components/HelloWorld.vue'
import Privacy from '../components/privacy.vue'
import Agreement from '../components/agreement.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HelloWorld
  }, {
		path: '/privacy',
		name: 'privacy',
		component: Privacy
	}, {
		path: '/agreement',
		name: 'agreement',
		component: Agreement
	}
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})

export default router
