<template>
  <router-view/>
</template>

<style lang="less">
* {
  margin: 0;
  padding: 0;
}
html, body, #app {
  height: 100%;
}
body {
  background-image: url('./assets/bg.jpg');
  background-size: cover;
  background-position: center center;
  background-attachment: fixed;
}
//#app {
//  font-family: Avenir, Helvetica, Arial, sans-serif;
//  -webkit-font-smoothing: antialiased;
//  -moz-osx-font-smoothing: grayscale;
//  text-align: center;
//  color: #2c3e50;
//}
</style>
