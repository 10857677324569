<script setup>
	import { useRouter } from 'vue-router'
	const router = useRouter()

	const handleBack = () => {
		router.push('/')
	}
</script>
<template>
	<div class="agreement">
		<div class="agreement-header">
			<span @click="handleBack">&lt;</span>
			<span>荣耀金业用户协议</span>
			<span style="opacity: 0;">&gt;</span>
		</div>
		<div class="agreement-body">
			<div>符合条件的荣耀金业用户（以下简称“用户”或“您”）。本协议中提及的荣耀金业的权利的行使和义务的履行由荣耀金业内部负责相应区域及相应业务范围的公司分别行使和履行。</div>
			
			 
			
			<div class="weight">一、 荣耀金业服务协议的确认</div>
			
			 <div class="weight">1、本协议有助于您了解荣耀金业为您提供的服务内容及您使用服务的权利和义务，请您仔细阅读。您一经同意本协议或通过注册荣耀金业账户使用本服务即视为对本协议全部条款已充分理解并完全接受，本协议在荣耀金业与您之间成立并生效。若您不同意本协议（包括荣耀金业此后对其不时做出的修订），应不使用或立即停止使用荣耀金业提供的服务（但在修订前已经发生的业务除外）。</div>
			
			<div>2、如本协议发生变更，荣耀金业将通过荣耀金业平台公告的方式提前予以公布，变更内容的生效时间以公告时间为准。若您在本协议变更后继续使用荣耀金业服务的，表示您接受变更后的协议，变更后的协议因您继续使用荣耀金业服务的行为而生效，同时，您将遵循变更后的协议内容使用荣耀金业服务。</div>
			
			<div>3、本协议内容以及荣耀金业已经发布的各类规则、操作流程等所有规则共同组成本协议不可分割的一部分，与本协议具有同等的法律效力。</div>
			
			<div>4、如您为无民事行为能力人（不满捌周岁的未成年人或不能辨认自己行为的成年人及未成年人）或为限制民事行为能力人（捌周岁以上的未成年人或不能完全辨认自己行为的成年人），请告知您的监护人，并在您监护人的指导下阅读本协议和使用荣耀金业服务。若您是中华人民共和国行政管辖区域以外的用户，除遵守中华人民共和国法律外您还需同时遵守您所属国家或地区的法律，且您确认，订立并履行本协议不受您所属、所居住或开展经营活动或其他业务的国家或地区法律法规的限制。</div>
			
			 
			
			<div class="weight">二、 定义</div>
			
			<div>1、 用户：指注册、使用、浏览荣耀金业服务的自然人或法人或其他组织。</div>
			
			<div>2、  荣耀金业平台：指由荣耀金业向用户提供的投融资信息（含金融产品信息）发布与管理、投融资产品管理、资金结算等服务的金融信息服务系统，荣耀金业平台包括荣耀金业App等（具体App名称以实际为准）。</div>
			
			<div>3、 荣耀金业账户：指您通过在荣耀金业账户系统中申请注册的个人账户，或用户个人关联至荣耀金业平台的账户。</div>
			
			<div>4、 身份要素：指荣耀金业为提供相关服务的需要，用于识别用户身份的信息要素，包括但不限于用户的账户、密码、数字证书、短信校验码、手机号码、身份证件号码及指纹信息、人脸信息等。</div>
			
			<div class="weight">三、 使用荣耀金业服务的注意事项</div>
			
			<div>为有效保障您使用荣耀金业服务时的合法权益，荣耀金业提醒您注意以下事项：</div>
			
			<div>（一）  身份验证</div>
			
			<div>1、 您在注册、使用荣耀金业服务的过程中，应提供合法、真实、有效、准确并完整的资料。 为了能将账户资金变动情况及时通知到您，以及更好保障您的账户安全，如该等资料发生变更，请您及时通知荣耀金业。<text class="weight">为了及时有效地验证您的信息（包括但不限于身份信息、账户信息等）和持续、便捷地为您提供服务，根据法律法规及监管规定要求或荣耀金业认为有需要时，您同意荣耀金业可以把您的信息进行保存、整理和加工或提供给第三方，也同意第三方可以把您的信息提供给荣耀金业，以便荣耀金业进行验证（具体详见本产品隐私政策及特定场景下的信息处理规则）。您应确保荣耀金业账户绑定的电子邮箱或手机号均为您本人所有并使用，如您使用了他人的电子邮箱或手机号的，为避免给电子邮箱或手机号所有人或使用人带来不便或不利影响，也为了您的资金安全，荣耀金业可能将该邮箱或手机号从您的会员号或账户中删除并解除关联。</text></div>
			
			<div>2、 为了满足相关法律法规及监管规定的要求，您同意按照荣耀金业的要求及通知的时间提供您的身份信息以完成身份验证，否则您可能无法进行收款、提现、享受金融产品信息服务、购买保险产品、申请贷款等操作，且荣耀金业可能依法对您的账户余额进行止付或注销您的账户。</div>
			
			<div>（二）  注意事项</div>
			
			<div>1、  您认可荣耀金业账户的使用记录、数据等均以荣耀金业的系统记录为准。如您对该等数据存有异议的，可及时提出异议，并提供相关证据供核实。</div>
			
			<div>2、 荣耀金业将在为您进行投资和收益等服务的过程中，根据有关文件、协议和本网站页面的相关规则、说明等向您收取必要的服务和管理费用，其具体内容、比例、金额等事项请参见有关文件、协议及本网站相关页面的规则和说明。您同意，荣耀金业有权不定期调整前述服务或管理费用的类型或金额等具体事项，并根据本协议和相关规则进行公告、修改。</div>
			
			<div>3、 您确认与同意上述线上投资、线上支付、资金结算以及查询等服务涉及荣耀金业与第三方支付机构或金融机构的合作。您同意：（1）受第三方支付机构或金融机构可能仅在工作日进行资金代扣及划转的现状等各种原因所限，荣耀金业在页面提示的资金到账时间仅供您参考，并不构成任何承诺；（2）您一旦使用前述服务，即表示用户授权荣耀金业按照指令操作。就前述服务，您应按照有关文件及第三方支付机构或金融机构的规定支付第三方的费用。</div>
			
			<div><text class="weight">4、 身份要素是荣耀金业识别您身份的依据，请您务必妥善保管。使用身份要素进行的任何操作、发出的任何指令均视为您本人做出。因您的原因造成的账户、密码等信息被冒用、盗用或非法使用，由此引起的一切风险、责任、损失、费用等应由您自行承担。</text>您同意：</div>
			
			<div>（1）    基于不同的终端以及您的使用习惯，我们可能采取不同的验证措施识别您的身份。例如您的荣耀金业账户在新设备首次登录的，我们可能通过密码加校验码的方式识别您的身份。</div>
			
			<div class="weight">（2）   为保障您的资金安全，请把手机及其他设备的密码设置成与荣耀金业账户不一致的其他密码。如您发现有他人冒用、盗用或非法使用您的账户及密码，或您的手机或其他有关设备丢失时，请您立即以有效方式通知荣耀金业；您还可以向荣耀金业申请暂停或停止荣耀金业服务。荣耀金业会将前述情况同步给荣耀金业关联公司旗下网站，以保障您的合法权益。您在持续登录任一荣耀金业关联公司的网站时段结束时，请以正确步骤退出网站。您理解荣耀金业对您的请求采取行动需要合理时间，如荣耀金业未在合理时间内采取有效措施，导致您损失扩大的，荣耀金业将就扩大的损失部分承担责任，但荣耀金业对采取行动之前已执行的指令不承担责任。</div>
			
			<div class="weight">（3）   荣耀金业账户仅限您本人使用，不可转让、借用、赠与、继承，但荣耀金业账户内的相关财产权利义务可被依法继承，法律或监管规定另有要求的除外。</div>                                
			
			<div class="weight">（4）   基于运行和安全的需要，荣耀金业有权暂停或者限制您荣耀金业账户内的部分服务功能，或增加新的功能。</div>
			
			<div class="weight">5、 您理解，荣耀金业服务有赖于系统的准确运行及操作。若出现系统差错、故障或其他原因引发的展示错误、您或荣耀金业不当获利等情形，您同意荣耀金业可以采取更正差错、扣划款项等适当纠正措施。</div>
			
			<div>（三）   风险提示</div>
			
			<div class="weight">1、  您在决定购买金融产品及其他金融服务前，您清楚并理解进行金融产品将面对市场波动的风险，因此，您应对荣耀金业发布的产品、服务信息进行全面细致的了解，审慎的评估风险，独立自主作出决定。</div>
			
			<div class="weight">2、  荣耀金业不对您的任何行为及产生的后果提供任何明示、默示的担保。荣耀金业向您提供的各种信息和资料仅为参考，您自行承担金融风险。</div>
			
			 
			
			<div class="weight">四、 用户合法使用荣耀金业服务的承诺</div>
			
			<div>1、  您应遵守中华人民共和国相关法律法规，不得将荣耀金业服务用于任何非法目的（包括用于反洗钱法律法规所禁止和限制的交易），也不得以任何非法方式使用荣耀金业服务。</div>
			
			<div class="weight">2、  您不得利用荣耀金业服务从事侵害他人合法权益之行为或违反国家法律法规， 否则荣耀金业有权进行调查、延迟或拒绝结算或停止提供荣耀金业服务，且您应独立承担所有相关法律责任，因此导致荣耀金业或其他方受损的，您应独立承担赔偿责任。</div>
			
			<div>3、  上述1和2适用的情况包括但不限于：</div>
			
			<div>（1）    侵害他人名誉权、隐私权、商业秘密、商标权、著作权、专利权等合法权益。</div>
			
			<div>（2）    违反依法定或约定之保密义务；</div>
			
			<div>（3）    冒用他人名义使用荣耀金业服务；</div>
			
			<div>（4）    从事不法交易行为， 如洗钱、恐怖融资、贩卖枪支、毒品、禁药、传播、使用盗版软件、黄色淫秽物品或其他荣耀金业认为不得使用荣耀金业服务进行交易的物品等；</div>
			
			<div>（5）    提供赌博资讯或以任何方式引诱他人参与赌博；</div>
			
			<div>（6）    未经授权使用他人银行卡，或利用信用卡套取现金；</div>
			
			<div>（7）    从事任何可能侵害荣耀金业服务系统、数据之行为；</div>
			
			<div class="weight">4、  您不得对荣耀金业的系统和程序采取反向工程手段进行破解，不得对上述系统和程序（包括但不限于源程序、目标程序、技术文档、客户端至服务器端的数据、服务器数据）进行复制、修改、编译、整合或篡改，不得修改或增减荣耀金业系统的任何功能。</div>
			
			<div class="weight">五、 信息使用与隐私保护</div>
			
			1、  如您通过签署协议等方式获得他人的个人信息，您同意并承诺不得恶意使用他人信息或将他人个人信息用于任何非法目的。
			
			2、  荣耀金业重视对您隐私的保护。关于您的身份资料和其他特定资料依据本产品隐私政策受到保护与规范，详情请参阅本产品隐私政策。
			
			<div class="weight">六、 终止服务</div>
			
			<div class="weight">荣耀金业在认为可能发生危害金融安全等情形时，可先行暂停、中断或终止向您提供本协议项下的全部或部分服务，必要情况下将注册资料封存、移除或删除，基于该等情形的暂停、中断、终止全部或部分服务，无需向您或其他第三方承担任何责任。前述情形包括但不限于：</div>
			
			<div>1、  认为您提供的资料不具有真实性、有效性或完整性；</div>
			
			<div>2、  发现异常或存在异议或有违法嫌疑时；</div>
			
			<div>3、  认为您使用荣耀金业账户涉嫌洗钱、套取现金、传销、恶意使用他人身份信息或使用他人身份信息参与违法活动等情形；</div>
			
			<div>4、  认为您已经违反本协议中的约定及精神；</div>
			
			<div>5、  您在使用收费服务时未按约定支付相应的费用；</div>
			
			<div>6、  用户个人的荣耀金业账户被注销；</div>
			
			<div>7、  有理由认为违反国家法律或侵害荣耀金业或他人利益的情形。</div>
			
			
			<div class="weight">七、 不可抗力、免责及责任限制</div>
			
			<div class="weight">（一）免责条款</div>
			
			<div class="weight">因下列原因导致荣耀金业无法正常提供服务，荣耀金业不承担责任：</div>
			
			<div class="weight">1、  非因荣耀金业原因造成的系统或设备问题，如因病毒、木马、恶意程序攻击、网络拥堵、通讯故障、电力故障、银行原因、第三方服务瑕疵或政府行为等原因造成的系统不稳定、系统或设备故障、系统停机维护或升级等；</div>
			
			<div class="weight">2、  因台风、地震、洪水、雷电或恐怖袭击等不可抗力原因；</div>
			
			<div class="weight">3、  用户的电脑软硬件和通信线路、供电线路出现故障的；</div>
			
			<div class="weight">4、  用户操作不当或通过非荣耀金业授权或认可的方式使用荣耀金业服务的；</div>
			
			<div class="weight">5、  尽管有前款约定，荣耀金业将采取合理行动积极促使服务恢复正常。如果因荣耀金业的原因，导致您无法正常使用荣耀金业的服务，致使您的合法权益受损，荣耀金业将承担相应的法律责任。</div>
			
			<div class="weight">（二）荣耀金业责任范围及责任限制</div>
			
			<div class="weight">1、  荣耀金业用户信息是由用户本人自行提供的，荣耀金业无法保证该信息之准确、有效和完整。</div>
			
			<div class="weight">2、 荣耀金业可能同时为您及您的对手方提供荣耀金业服务，您同意对荣耀金业可能存在的该等行为予以明确豁免任何实际或潜在的利益冲突，并不得以此来主张荣耀金业在提供服务时存在法律上的瑕疵。</div>
			
			 
			
			<div class="weight">八、 知识产权的保护</div>
			
			<div>1、荣耀金业及其关联公司所有系统的所有内容，包括但不限于著作、图片、档案、资讯、资料、网站架构、网站画面的安排、网页设计，均由荣耀金业或其关联公司依法拥有其知识产权，包括但不限于商标权、专利权、著作权、商业秘密等。</div>
			
			<div>2、非经荣耀金业或其关联公司书面同意，任何人不得擅自使用、修改、反向编译、复制、公开传播、改变、散布、发行或公开发表本网站程序或内容。</div>
			
			<div>3、尊重知识产权是您应尽的义务，如有违反，您应承担损害赔偿责任。</div>
			
			 
			
			<div class="weight">九、法律适用与管辖</div>
			
			<div class="weight">本协议之效力、解释、变更、执行与争议解决均适用中华人民共和国法律。因本协议产生之争议，均应依照中华人民共和国法律予以处理，并由本协议签订地有管辖权的人民法院管辖。</div>
		</div>
	</div>
</template>

<style scoped lang="less">
	.agreement {
		height: 100%;
		&-header {
			height: 100px;
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 0 5%;
			font-size: 20px;
			color: #fff;
			text-align: center;
			background: rgba(0, 0, 0, 0.5);
			span:first-child {
				font-size: 24px;
				cursor: pointer;
			}
		}
		&-body {
			height: calc(100% - 140px);
			overflow-y: auto;
			color: #fff;
			padding: 20px;
			.first {
				text-indent: 2rem;
			}
			.weight {
				font-weight: bold;
				margin-top: 25px;
			}
		}
	}
</style>