<script setup>
	import { useRouter } from 'vue-router'
	const router = useRouter()
	
	const handleBack = () => {
		router.push('/')
	}
</script>
<template>
	<div class="privacy">
		<div class="privacy-header">
			<span @click="handleBack">&lt;</span>
			<span>荣耀金业隐私政策</span>
			<span style="opacity: 0;">&gt;</span>
		</div>
		<div class="privacy-body">
			<div class="first">【特别提示】尊敬的用户，感谢您使用荣耀金业为您提供的应用服务。我们始终重视您的隐私，并全力遵守国家法律法规的要求对您的个人信息进行保护。我们于2023年8月5日更新了《荣耀金业隐私政策》。本次更新我们采取通知形式进行。请花一些时间熟悉我们的隐私政策，如果您有任何问题，请通过正文列举的方式联系我们。如我们提供的某款产品有单独的隐私政策或相应的用户服务协议当中存在特殊约定，则该产品的隐私政策将优先适用；该产品隐私政策和用户服务协议未涵盖的内容，以本隐私政策内容为准。</div>
				
				<div>版本更新时间：2023年 8月5日</div>
				
				<div>版本生效时间：2023年 9月5日</div>
				 
				<div>我们非常重视您的隐私。本隐私政策是针对荣耀金业App（以下简称：“平台”或“本平台”）做出的隐私方面的陈述与承诺。</div>
				<div>本隐私政策在制定时充分考虑到您的需求，您全面了解我们的个人信息收集和使用惯例，同时确保您最终能控制提供给我们的个人信息，这一点至关重要。本隐私政策规定我们如何收集、使用、披露、处理和存储您使用我们的产品、服务时提供给我们的信息。本隐私政策下“个人信息”指通过信息本身或通过关联其他信息后能够识别特定个人的数据。我们将严格遵守本隐私政策来使用这些信息。对于本隐私政策中涉及的敏感个人信息的处理以及可能对您的权利义务有重大影响的条款，我们将采用加粗形式进行提示，请您留意阅读。</div>
				
				 
				
				<div class="weight">目录</div>
				
				<div>为使您清晰而详尽地了解我们的个人信息收集使用惯例，请参考以下目录阅读对应章节。</div>
				
				<div>一、我们收集哪些信息以及如何使用信息</div>
				
				<div>二、我们如何使用 Cookie 和同类技术</div>
				
				<div>三、我们如何保存和保护您的个人信息</div>
				
				<div>四、未成年人信息的保护</div>
				
				<div>五、第三方网站和服务</div>
				
				<div>六、本政策如何更新</div>
				 
				
				<div class="weight">一、 我们收集哪些信息以及如何使用信息</div>
				
				<div>收集个人信息的目的在于向您更为优质地提供我们的产品和/或服务，并且保证我们遵守适用的相关法律、法规及其他规范性文件。我们列举了产品和/或服务的核心功能，及该功能所相应需要收集的个人信息，我们谨遵最小必要原则，充分尊重您的自主选择权，针对部分附加功能，您有权根据自己的意愿选择是否使用并提供该服务所须信息，您不同意收集这部分信息不影响您使用本产品和/或服务的其他功能。请您理解，我们向您提供的产品与/或服务将不断更新变化，同时也取决于您所使用App的具体版本。如果您选择使用本政策中尚未列明的其他产品与/或服务时，在我们收集您的个人信息前，我们会通过业务协议、页面提示的方式向您详细说明信息收集的目的、方式、范围并征求您的明确同意。</div>
				
				<div class="weight">(一) 我们向您收集哪些信息（敏感个人信息已加粗标识）</div>
				
				<div><text class="weight">【设备信息】</text>可用于识别您设备的数据,如设备识别信息,</div>
				
				<div>即包括IMEI或SN、Android 10及以上版本的OAID、IDFA、UUID（仅iOS设备）等在内的描述个人常用设备基本情况的信息。同时，请您理解，当您将应用移动切换到设备后台运行时，由于网络异常或系统原因，您此前使用应用而产生的信息收集行为可能无法立即停止，从而导致短暂的后台信息收集行为。</div>
				
				<div class="weight">【生物识别信息】人脸信息（含人脸照片、脸部图像/视频、面容ID）、指纹信息。</div>
				
				<div class="weight">【内容信息】您主动上传的照片、投诉相关内容。</div>
				
				<div><text class="weight">【使用信息】</text>使用该应用程序的频率、该应用程序内发生的事件、累计使用、性能数据、浏览行为信息、应用程序崩溃发生的记录及位置、互联网使用信息、互联网使用行为。</div>
				
				<div class="weight">【位置信息】地理位置信息。</div>
				
				
				<div class="weight">(二) 设备权限调用说明</div>
				
				<div>在您使用本服务时可能会触发以下与个人信息相关的系统敏感权限。为使您更好地管理您的信息，<text class="weight">我们会在产品使用过程权限申请时同步告知您该权限的使用目的，以及如您不开启该权限可能对您使用服务的影响，请您仔细查阅。您在使用特定功能前，可选择是否授权开通以下权限。同时，您可以随时通过设备权限设置页面自己更改授权情况。</text></div>
				
				<div>以下为相关功能及调用权限的细节，请您查阅：</div>
				
				<div><text class="weight">1. 指纹识别权限（Android版本）</text>：在适用设备上，当您使用指纹识别解锁时，您需要在您的设备上提前录入指纹信息，并在使用服务时在您的设备上完成信息验证。若您不开启此权限，不影响您选择其他解锁方式。</div>
				
				<div>我们申请获取的权限将与提供的服务功能保持一致，若我们提供的业务功能调整，我们申请的设备权限也将随之调整。我们在首次调用设备权限时会单独提示您是否开启并对权限申请目的进行解释。请您注意，您开启这些权限即代表您授权我们可以收集和使用这些个人信息来实现上述的功能，您关闭权限即代表您取消了这些授权，则我们将不再继续收集您的这些个人信息，也无法为您提供上述与这些授权所对应的功能。您关闭权限的决定不会影响此前基于您的授权所进行的个人信息的处理。</div>
				
				
				<div class="weight">(三) 征得授权同意的例外</div>
				
				<div>您充分知晓，依据相关法律法规及国家标准，以下情形中，我们可能会依法收集、使用个人信息无需征得您的同意：</div>
				
				<div>1. 与个人信息控制者履行法律法规规定的义务相关的；</div>
				
				<div>2. 与国家安全、国防安全直接相关的；</div>
				
				<div>3. 与公共安全、公共卫生、重大公共利益直接相关的；</div>
				
				<div>4. 与刑事侦查、起诉、审判和判决执行等直接相关的；</div>
				
				<div>5. 出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到本人授权同意的；</div>
				
				<div>6. 所涉及的个人信息是个人信息主体自行向社会公众公开的；</div>
				
				<div>7. 根据个人信息主体要求签订和履行合同所必需的；</div>
				
				<div>8. 从合法公开披露的信息中收集的个人信息的，如合法的新闻报道、政府信息公开等渠道；</div>
				
				<div>9. 维护所提供的产品与/或服务的安全稳定运行所必需的，例如发现、处置产品与/或服务的故障；</div>
				
				<div>10. 个人信息控制者为新闻单位，且其开展合法的新闻报道所必需的；</div>
				
				<div>11. 个人信息控制者为学术研究机构，出于公共利益开展统计或学术研究所必要，且对外提供学术研究或描述的结果时，对结果中所包含的个人信息进行去标识化处理的。</div>
				
				 
				
				<div class="weight">二、 我们如何使用Cookie和同类技术</div>
				
				<div>收集哪些信息以及如何使用这些信息？</div>
				
				<div>1. 本平台和第三方服务供应商使用Cookie、标签和脚本等技术，这些技术用于分析趋势、管理网站、追踪用户的网站活动并收集关于整个用户群体的统计信息。我们会收到通过以上技术收集的个体或汇总的分析报告。此等技术帮助我们更好地了解您的行为，使我们了解用户浏览我们网站的哪些部分，衡量广告和网络搜索的效果并加以改善。我们将通过 Cookie 和其他技术收集的信息视为非个人信息。但是，如果当地法律将 Internet 协议 (IP) 地址或类似识别标记视为个人信息，则我们亦将此等识别标记视为个人信息。</div>
				
				<div>2. 日志文件：和大部分网站一样，我们收集特定信息并保存在日志文件中。此类信息可能包括互联网协议 (IP) 地址、浏览器类型、互联网服务供应商 (ISP)、引用/退出页面、操作系统、日期/时间戳和/或点击流数据。我们不会把自动收集的日志数据与我们收集的其他信息进行关联。</div>
				
				<div>3. 移动分析：在某些移动应用中，我们使用移动分析软件，以更好地了解我们的移动软件在您的手机中的功能。此款软件可能记录以下信息，如您使用该应用程序的频率、该应用程序内发生的事件、累计使用、性能数据及应用程序崩溃发生的位置。我们不会将存储于分析软件内的信息关联到您在移动应用程序中提交的任何个人信息。</div>
				
				<div>4. 本地存储-HTML5/Flash：我们使用本地存储对象 (LSO)，例如 HTML5 或 Flash 存储内容和偏好。根据您的网页浏览行为，与我们合作在网站上提供某些功能或投放广告的第三方也会通过 HTML5 或 Flash Cookie 来收集和存储相关信息。各种浏览器提供了各自的管理工具来删除 HTML5 本地存储对象。</div>
				 
				
				<div class="weight">三、 我们如何保存和保护您的个人信息</div>
				
				<div class="weight">(一) 安全措施</div>
				
				<div>我们承诺保证您的个人信息安全。为了防止未经授权的访问、披露或其他类似风险，我们落实了合理的物理、电子和管理措施流程，保护我们从荣耀金业App上收集的信息。我们将采取所有合理的措施保护您的个人信息。</div>
				
				<div>您的个人信息全都被储存在安全的服务器上，并在受控设施中受到保护。我们依据重要性和敏感性对您的数据进行分类，并且保证您的个人信息具有合理的安全等级。同样，我们对以云为基础的数据存储设有专门的访问控制措施。总而言之，我们定期审查信息收集、储存和处理实践，包括物理安全措施，以防止任何未经授权的访问和使用。</div>
				
				<div>我们会严格甄选业务合作伙伴和服务提供商，将在个人信息保护方面的要求落实到双方的商务合同或审计、考核等活动中。我们保证通过访问这些信息来帮助向您提供产品或服务的员工和第三方服务供应商具有严格的合同保密义务，如果未能履行这些义务，其将会受到纪律处分或被终止合作。如在合作过程中，因共享或转让您的个人信息发生安全事件，对您的合法权益造成损害的，我们将承担相应的责任。</div>
				
				<div>我们会举办安全和隐私保护培训课程、测试与宣传活动，加强员工对于保护个人信息重要性的认识。我们将采取所有可行的措施保护您的个人信息。但是，您应当意识到互联网的使用并不总是安全的，因此，我们不能保证在通过互联网双向传输时任何个人信息的安全性或完整性，但我们将尽我们的技术能力采取各项加密或其他保护措施。</div>
							
				<div>我们将对于个人数据的泄露，及时通知相关的监管机构，并根据适用法律要求（包括您所在地的数据保护法规），通知数据主体相关的数据泄露事件。</div>
				
				<div class="weight">(二) 您能做什么?</div>
				
				<div>1. 您可以通过不向任何人（除非此人经您正式授权）披露您的登录密码或账号信息，您可以为您的账号设置唯一的密码，以防止其他网站密码泄露危害您在本平台的账号安全。无论何时，请不要向任何人（包括自称是荣耀金业相关产品客服的人士）透露您收到的验证码。无论您何时登录荣耀金业App，尤其是在他人的计算机或公共互联网终端上登录时，在会话结束时您总应注销登出。</div>
				
				<div>2. 我们不对因您未能保持个人信息的私密性而导致第三方访问您的个人信息进而造成的安全疏漏承担责任。尽管有上述规定，如果发生其他任何互联网用户未经授权使用您账号的情况或其他任何安全漏洞，您应当立即通知我们。</div>
				
				<div>您的协助将有助于我们保护您个人信息的私密性。</div>
				
				<div class="weight">(三) 数据存储及保留政策</div>
				
				<div>我们基于本隐私政策中所述的信息收集的目的所必需的期间，或者遵守适用的相关法律法规及监管要求保留个人信息。个人信息在完成收集目的，或在我们确认您的删除或注销申请后，或我们终止运营相应产品或服务后，我们将停止保留，并做删除或匿名化处理。在您终止使用我们的产品和服务后，我们会停止对您的信息的收集和使用，法律法规或监管部门另有规定的除外。在“您的权利”部分也有详细描述，可以参阅。</div>
				
				<div>您的信息将会保存至中华人民共和国境内。我们将在中华人民共和国大陆地区运营中收集和产生的个人信息，存储在中国大陆地区的数据中心。</div>
				
				
				<div class="weight">四、 未成年人信息的保护</div>
				
				<div>我们认为监督孩子使用我们的产品或服务是家长或监护人的责任。但是，我们拒绝获得未成年人的个人信息，或者向这类人群发送任何促销资料。</div>
				
				<div>我们不会寻求或试图寻求接收来自未成年人的任何个人信息。如果家长或监护人有理由相信未成年人未经事先同意而向我们提交了个人信息，请通过本政策所述联系方式联系我们以确保删除此类个人信息，并保证未成年人取消订阅任何适用的服务。</div>
				
				<div class="weight">五、 第三方网站和服务</div>
				
				<div>我们的隐私政策仅适用于我们提供的产品或服务。当您所使用的产品或服务中包括第三方的产品或服务，如涉及人脸识别等类型，其中一些会以第三方网站的链接形式提供，还有一些会以SDK、API等形式接入。鉴于第三方是自主收集并使用您的个人信息，当您使用这些产品或服务时，我们强烈建议您花时间阅读该第三方的隐私政策，就像阅读我们的政策一样。</div>
				
				<div class="weight">六、 本政策如何更新</div>
				
				<div>我们会基于业务和技术的变更对隐私政策进行定期审核，我们可能会更新本隐私政策。如果我们对本隐私政策进行重大变更，我们将通过您登记的联系方式如电子邮件（向您账号指定的邮箱地址发送）或通过App内弹窗通知您，这样您可以了解我们收集的信息以及我们如何使用这些信息。此类隐私政策变化将从通知或网站规定的生效日期开始适用。您继续使用产品和网站、手机和/或其他任何设备上的服务，将被视为接受更新的隐私政策。在我们向您收集更多的个人信息或我们因为新的目的使用或披露您的个人信息时，我们会再次征得您的同意。</div>
			
		</div>
	</div>
</template>

<style scoped lang="less">
	.privacy {
		height: 100%;
		&-header {
			height: 100px;
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 0 5%;
			font-size: 20px;
			color: #fff;
			text-align: center;
			background: rgba(0, 0, 0, 0.5);
			span:first-child {
				font-size: 24px;
				cursor: pointer;
			}
		}
		&-body {
			height: calc(100% - 140px);
			overflow-y: auto;
			color: #fff;
			padding: 20px;
			.first {
				text-indent: 2rem;
			}
			.weight {
				font-weight: bold;
				margin-top: 25px;
			}
		}
	}
</style>