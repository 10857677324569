<script setup>
import { ref, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import QrcodeVue from 'qrcode.vue'

const qrCode = ref(null)
const router = useRouter()
let codeValue = ref('http://47.106.154.10/packages/荣耀金业.apk')
let exeValue = ref('http://47.106.154.10/packages/荣耀金业.exe')

onMounted(() => {
	initImage()
})
const convertCanvasToImage = (canvas) => {
	let image = new Image()
	image.src = canvas.toDataURL('image/png')
	return image
}
const initImage = () => {
	let canvas = document.getElementsByTagName('canvas')[0]
	let img = convertCanvasToImage(canvas)
	qrCode.value.append(img)
}
const handleJump = (val) => {
	router.push('/' + val)
}
const handleClick = () => {
  window.location.href = exeValue.value
}

</script>
<template>
  <div class="hello">
    <div class="hello-header">荣耀金业</div>
    <div class="hello-body">
      <el-row :span="24" :gutter="20">
        <el-col :xs="24" :sm="12">
          <div class="hello-body-pc">
            <div class="hello-body-item-title">桌面交易平台</div>
            <div class="hello-body-item-tip">PC用户可以下载桌面版交易平台来体验完善的交易功能</div>
            <el-button type="primary" plain @click="handleClick">下载平台</el-button>
          </div>
        </el-col>
        <el-col :xs="24" :sm="12">
          <div class="hello-body-phone">
            <div class="hello-body-item-title">手机交易平台</div>
            <div class="hello-body-item-tip">可通过安卓应用商城安装手机版交易软件或扫描二维码安装</div>
            <div class="hello-body-item-code">
              <div ref="qrCode" class="hello-body-item-code-img"></div>
							<qrcode-vue :value="codeValue" :size="200" style="display: none;"></qrcode-vue>
              <img class="logo-img" src="../../public/honour.png" alt="" />
            </div>

          </div>
        </el-col>
      </el-row>
    </div>
    <div class="hello-footer">
      <a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank" class="copyright">©2023 粤ICP备2023103999号</a>
			<span class="copy-private" @click="handleJump('privacy')">隐私政策</span>
			<span class="copy-user" @click="handleJump('agreement')">用户协议</span>
    </div>
  </div>
</template>

<style scoped lang="less">
.hello {
  height: 100%;
  .common() {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
  }
  &-header {
    height: 100px;
    background: rgba(0, 0, 0, 0.5);
    font-size: 36px;
    letter-spacing: 2px;
    .common();
  }
  &-body {
    height: calc(100% - 150px);
    overflow-y: auto;
		padding: 0 20px;
    .el-row {
      height: 100%;
    }
    &-pc {
      margin-top: 50px;
      text-align: center;
      .hello-body-item-title {
        font-size: 32px;
        color: #fff;
      }
      .hello-body-item-tip {
        font-size: 16px;
        margin: 20px 0;
        color: #f1f1f1;
      }

    }
    &-phone {
      margin: 50px 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      .hello-body-item-title {
        font-size: 32px;
        color: #fff;
      }
      .hello-body-item-tip {
        font-size: 16px;
        margin: 20px 0;
        color: #f1f1f1;
      }
      .hello-body-item-code {
        height: 220px;
        width: 220px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #fff;
        position: relative;
        .logo-img {
          position: absolute;
          width: 40px;
          left: 90px;
          top: 90px;
          background: #fff;
					border-radius: 4px;
        }
				&-img {
					width: 200px;
					height: 200px;
					:deep(img) {
						width: 200px;
						height: 200px;
					}
				}
      }
    }
  }

  &-footer {
    height: 50px;
    background: #000;
		font-size: 14px;
    .common();
		.copyright {
			.spanCom();
			margin-right: 30px;
			color: #fff;
			text-decoration: none;
		}
		.copy-private {
			.spanCom();
			border-right: 2px solid #eee;
			padding-right: 10px;
		}
		.copy-user {
			.spanCom();
			margin-left: 10px;
		}
		.spanCom() {
			height: 20px;
			line-height: 20px;
			display: inline-block;
			cursor: pointer;
			&:hover {
				text-decoration: underline;
			}
		}
  }
}
</style>
